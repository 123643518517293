<template>
 <div>
    <Header titleurl="recruit"></Header>
    <!-- <div class="center"> -->
        <!-- 信息版 -->
				<!-- <div class="warning">
					<div class="voice">
						<img src="../../assets/img/voice.png">
					</div>
					<div class="warningtext">
						<div><span>7月起平台将要求所有招聘企业经过认证，未认证企业发布职位将无法在线展示。</span><a>点击快速认证</a></div>
						<div><span>亲爱的<span></span>,您的账户还可以发布10条免费信息</span><a>了解用户等级与发帖规则</a></div>
						<div><span>为了给您提供更好的服务，特邀您来参与我们的调研。</span><a>参与调研</a></div>
					</div>
				</div>
    </div> -->
    <div class="center" style="margin-top:30px">
        <!-- .msg-active是在信息填写哪个阶段的时候 -->
        <div class="serialnumber msg-active">
          <div class="circle">
            <div>1</div>
          </div>
          <div class="circlefont">企业信息填写</div>
          <div class="bottom">&nbsp;</div>
          <div class="smallcircle"></div>
        </div>
        <div class="serialnumber" id="two">
          <div class="circle">
            <div>2</div>
          </div>
          <div class="circlefont">职位信息填写</div>
          <div class="bottom">&nbsp;</div>
          <div class="smallcircle"></div>
        </div>
        <div class="serialnumber">
          <div class="circle">
            <div>3</div>
          </div>
          <div class="circlefont">职位发布完成</div>
          <div class="bottom">&nbsp;</div>
          <div class="smallcircle"></div>
        </div>
    </div>
    <div class="center" v-show="firstmessage">
          <el-form ref="formjob" :model="formjob" :rules="rulejob"  label-width="80px">
            <div class="positionmsg">企业信息</div>
            <el-form-item label="公司名称" prop="cid">
              <el-select placeholder="请选择公司名称" v-model="formjob.cid" @change="companycid()" style="width:30%;">
               <el-option
                v-for="item in company"
                :key="item.id"
                :label="item.companyname"
                :value="item.companyid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属行业" prop="trade">
              <el-select placeholder="请选择行业" v-model="formjob.trade" @change="tradeCodes()" style="width:30%;">
               <el-option value="" label="">请选择</el-option>
               <el-option
                v-for="item in indutrys"
                :key="item.code"
                :label="item.name"
                :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司规模" >
              <el-input placeholder="请选择公司规模" v-model="formjob.companySize" style="width:30%;"></el-input>
            </el-form-item>
            <el-form-item label="员工规模" >
              <el-input placeholder="请选择员工规模" v-model="formjob.employeeSize" style="width:30%;"></el-input>
            </el-form-item>
            <el-form-item label="公司简介" >
              <quill-editor v-model="formjob.companyProfile" ref="myQuillEditors" :options="editorOptions"></quill-editor>
            </el-form-item>
            <el-form-item label="公司地址" >
              <el-input placeholder="请输入详细地址" v-model="formjob.companyAddress" style="width:50%;"></el-input><el-button type="danger" @click="addurl()">添加地址</el-button>
            </el-form-item>
            <div class="positionmsg">工商信息</div>
            <el-form-item>
              <el-upload style="float:left;"
                class="upload-demo"
                drag
                multiple
                :headers="myHeaders"
                :action="uploadUrl"
                name="file"
                accept="image/png,image/gif,image/jpg,image/jpeg"
                :data="uploadData"
                :file-list="fileListGS"
                :on-success="busSuccess"
                :on-error="busError"
                :on-remove="busremove"
                list-type="picture"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </el-upload>
            </el-form-item>
            <div class="positionmsg">公司相册</div>
            <el-form-item>
              <el-upload style="float:left;"
                class="upload-demo"
                drag
                multiple
                accept="image/png,image/gif,image/jpg,image/jpeg"
                :headers="myHeaders"
                :action="uploadUrl"
                :data="uploadData"
                name="file"
                :file-list="fileListCom"
                :on-success="comSuccess"
                :on-error="comError"
                :on-remove="comremove"
                list-type="picture"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
              </el-upload>
            </el-form-item>
          </el-form>  
      <el-button type="primary" class="release" @click="release()">创建资料并发布</el-button>
    </div>
    <div class="center" v-show="secondmessage">
      <el-form ref="form" :model="form" :rules="rules"  label-width="80px">
        <div class="positionmsg">职位信息</div>
        <el-form-item label="职位名称" prop="position.name">
          <el-input placeholder="请输入职位名称" v-model="form.position.name" style="width:30%;"></el-input>
        </el-form-item>
        <el-form-item label="职位类别"  prop="position.parentId">
          <el-select placeholder="请选择职位类别" v-model="form.position.parentId" style="width:30%;">
            <el-option
            v-for="item in job"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招聘人数" prop="num">
          <el-input type="number" placeholder="请输入人数" v-model="form.num" style="width:30%;"></el-input><label class="lf">人</label>
        </el-form-item>
        <el-form-item label="每月薪资" >
          <el-select placeholder="请选择每月薪资范围" v-model="form.salaryCode" @change="salarys()" style="width:30%;">
            <el-option
            v-for="item in sals"
            :key="item.code"
            :label="item.name"
            :value="item.code">
            </el-option>
          </el-select>
          <el-checkbox class="checkboxm" v-model="isFace" @change="isFaces()">面议</el-checkbox>
        </el-form-item>
        <el-form-item label="职位福利" >
            <el-checkbox-group  v-model="welfarelist" @change="welfare()">
              <el-checkbox-button v-for="item in wels" :key="item.code" :label="item.name"></el-checkbox-button>
            </el-checkbox-group>
            <div class="addfuli">
              <el-input placeholder="最多输入6个字" style="width:15%;"></el-input>
              <el-button type="danger">添加</el-button>
            </div>
        </el-form-item>
        <el-form-item label="学历要求" >
            <el-radio-group v-model="form.educationCode" @change="edu()">
            <el-radio-button v-for="item in edus" :label="item.code" :key="item.code">{{item.name}}</el-radio-button></el-radio-button>
            </el-radio-group>
            <el-checkbox class="checkboxm" v-model="acceptFgra" @change="acceptFgras()">可接受应届生</el-checkbox>
        </el-form-item>
        <el-form-item label="工作年限" >
            <el-radio-group v-model="form.workExpCode" @change="workExp()">
            <el-radio-button v-for="item in workExps" :label="item.code" :key="item.code">{{item.name}}</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="工作地址" >
          <div class="address">{{address}}</div>
          <el-button type="danger" @click="addurl()">添加地址</el-button>
        </el-form-item>
      <div class="positionmsg">职位描述</div>
        <el-form-item label="任职要求:" >
            <quill-editor v-model="form.requires" ref="myQuillEditor" :options="editorOption"></quill-editor>
        </el-form-item>
        <el-form-item label="岗位职责:" >
            <quill-editor v-model="form.duty" ref="myQuillEditor" :options="editorOption"></quill-editor>
            <el-checkbox v-model="acceptDeform" @change="acceptDeforms()">可接受残疾人</el-checkbox>
        </el-form-item>
      <div class="positionmsg">联系方式</div>
        <el-form-item label="联系人" prop="contacts">
          <el-input placeholder="请填写联系人姓名" v-model="form.contacts" style="width:30%;"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" class="phone" prop="contactsPhone">
          <el-input placeholder="请填写联系电话" v-model="form.contactsPhone" style="width:20%;"></el-input>
          <el-checkbox v-model="phoneOpen" @click="phoneOpens()">不公开</el-checkbox>
          <img src="../../assets/img/public.png"/>
          <el-button type="danger">添加联系电话</el-button>
        </el-form-item>
        <el-form-item label="邮箱地址" >
          <el-input placeholder="请填写接收简历邮箱（选填）" v-model="form.contactsEmail" style="width:30%;"></el-input>
          <div class="email">选填，格式参考：username@mail.com，应聘简历将会同时被投递到您在此处填写的邮箱</div>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="nextstep" @click="nextstep()">下一步</el-button>
    </div>
    <div class="center" v-show="thirdmessage">
      <div class="thirdmsg">
        <div class="third">恭喜你，职位发布完成</div>
      </div>
    </div>
      <el-dialog
			:visible.sync="dialogVisible"
			top="25vh"
			width="40%"
			:before-close="handleClose">
			<el-row >
				<div class="amap-page-container" style="margin-top:-15px;">
				<div class="address">{{address}}</div>
				<el-amap
					vid="amaprecruitposition"  
					:center="center"
					:zoom="zoom"  
					class="amap-demo"
					:events="events">
					<el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
				</el-amap>
				<div style="text-align:center;margin-top:20px;">
					<el-button type="primary" size="mini" @click="locatefal"><span style="margin:0 30px">确定</span></el-button>
				</div>
				</div>
			</el-row>
		</el-dialog>
    <Footer></Footer>
    
 </div>

</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VDistpicker from 'v-distpicker';
import Utilcity from '@/utils/unilcity.js';
import {
  jobpost,
  screen,
  jobinfo,
  businessId,
  hrcompany,
  hrcompanyinfo,
} from '@/api/position';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
export default {
  name: 'position',
  components: {
    Header,
    Footer,
    quillEditor,
    VDistpicker,
  },
  data() {
    const self = this;
    return {
      uploadUrl: this.$uplodeUrl + '/utils/uploadfile',
      myHeaders: { Authorization: 'Bearer ' + token },
      fileListGS: [],
      fileListCom: [],
      uploadData: {
        fileType: 'picture',
        fileTypeCode: 'common',
      },
      business: [],
      cominess: [],
      // busfileList:[],
      // comfileList:[],
      welfarelist: [],
      welfareCodelist: [],
      editorOption: {},
      editorOptions: {},
      content: null,
      contents: null,
      value2: '',
      rules: {
        'position.name': [
          { required: true, message: '请输入职位名称', trigger: 'blur' },
        ],
        'position.parentId': [
          { required: true, message: '请选择职位类别', trigger: 'change' },
        ],
        contacts: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' },
        ],
        contactsPhone: [
          { required: true, message: '请填写联系电话', trigger: 'blur' },
        ],
      },
      rulejob: {
        cid: [{ required: true, message: '请选择公司名称', trigger: 'change' }],
        // tradeCode: [{ required: true, message: '请选择行业', trigger: 'change' }]
      },
      form: {
        workAddress: {
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          latitude: 0,
          longitude: 0,
          province: '',
          provinceCode: '',
          srid: 'GCJ-02',
        },
        position: {
          businessId: '',
          description: '',
          name: '',
          parentId: '',
        },
        acceptDeform: 0,
        acceptFgra: 0,
        phoneOpen: 0,
        contacts: '',
        contactsPhone: '',
        contactsEmail: '',
        duty: '',
        education: '',
        educationCode: '',
        isFace: 0,
        num: 0,
        reeCid: '3',
        requires: '',
        salary: '',
        salaryCode: '',
        welfare: '',
        welfareCode: '',
        workExp: '',
        workExpCode: '',
      },
      formjob: {
        albums: [],
        attachmentFiles: [],
        cid: null,
        fullname: '',
        trade: '',
        tradeCode: '',
        companySize: '',
        employeeSize: '',
        companyProfile: '',
        gisCoord: {
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          latitude: 0,
          longitude: 0,
          province: '',
          provinceCode: '',
          srid: 'GCJ-02',
        },
      },
      company: [],
      acceptDeform: false,
      acceptFgra: false,
      isFace: false,
      phoneOpen: false,
      cityCode: '410100',
      workExps: [],
      job: [],
      wels: [],
      sals: [],
      edus: [],
      indutrys: [],
      firstmessage: true,
      secondmessage: false,
      thirdmessage: false,
      dialogVisible: false,
      markers: [],
      center: [117.127665, 36.679559],
      zoom: 15,
      // address: '',
      lng: 117.127665,
      lat: 36.679559,
      address: '',
      addForm: {
        longitude: '',
        latitude: '',
      },
      events: {
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.addForm.longitude = lng;
          self.addForm.latitude = lat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all',
          });
          geocoder.getAddress([lng, lat], function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                self.address = result.regeocode.formattedAddress;
                self.form.workAddress.city =
                  result.regeocode.addressComponent.city;
                self.form.workAddress.province =
                  result.regeocode.addressComponent.province;
                self.form.workAddress.latitude =
                  result.regeocode.crosses[0].location.lat;
                self.form.workAddress.longitude =
                  result.regeocode.crosses[0].location.lng;
                self.form.workAddress.county =
                  result.regeocode.addressComponent.district;
                self.form.workAddress.countyCode =
                  result.regeocode.addressComponent.adcode;
                self.form.workAddress.cityCode =
                  result.regeocode.addressComponent.adcode.substring(0, 4) +
                  '00';
                self.form.workAddress.provinceCode =
                  result.regeocode.addressComponent.adcode.substring(0, 2) +
                  '0000';
                self.form.workAddress.srid = 'GCJ-02';
                self.formjob.companyAddress = result.regeocode.formattedAddress;
                self.formjob.gisCoord.city =
                  result.regeocode.addressComponent.city;
                self.formjob.gisCoord.province =
                  result.regeocode.addressComponent.province;
                self.formjob.gisCoord.latitude =
                  result.regeocode.crosses[0].location.lat;
                self.formjob.gisCoord.longitude =
                  result.regeocode.crosses[0].location.lng;
                self.formjob.gisCoord.county =
                  result.regeocode.addressComponent.district;
                self.formjob.gisCoord.countyCode =
                  result.regeocode.addressComponent.adcode;
                self.formjob.gisCoord.cityCode =
                  result.regeocode.addressComponent.adcode.substring(0, 4) +
                  '00';
                self.formjob.gisCoord.provinceCode =
                  result.regeocode.addressComponent.adcode.substring(0, 2) +
                  '0000';
                self.formjob.gisCoord.srid = 'GCJ-02';
                self.$nextTick();
              }
            }
          });
        },
      },
      location() {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20),
          showMarker: true,
          panToLocation: true,
          zoomToAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 5000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);
        function onComplete(data) {
          self.markers = [];
          self.form.workAddress.city = data.addressComponent.city;
          self.form.workAddress.province = data.addressComponent.province;
          self.form.workAddress.county = data.addressComponent.district;
          self.form.workAddress.countyCode = data.addressComponent.adcode;
          self.form.workAddress.cityCode =
            data.addressComponent.adcode.substring(0, 4) + '00';
          self.form.workAddress.provinceCode =
            data.addressComponent.adcode.substring(0, 2) + '0000';
          self.form.workAddress.latitude = data.position.lat;
          self.form.workAddress.longitude = data.position.lng;
          self.form.workAddress.srid = 'GCJ-02';
          self.formjob.gisCoord.city = data.addressComponent.city;
          self.formjob.gisCoord.province = data.addressComponent.province;
          self.formjob.gisCoord.county = data.addressComponent.district;
          self.formjob.gisCoord.latitude = data.position.lat;
          self.formjob.gisCoord.longitude = data.position.lng;
          self.formjob.gisCoord.countyCode = data.addressComponent.adcode;
          self.formjob.gisCoord.cityCode =
            data.addressComponent.adcode.substring(0, 4) + '00';
          self.formjob.gisCoord.provinceCode =
            data.addressComponent.adcode.substring(0, 2) + '0000';
          self.formjob.companyAddress = data.formattedAddress;
          self.formjob.gisCoord.srid = 'GCJ-02';
          self.lng = data.position.lng;
          self.lat = data.position.lat;
          self.addForm.longitude = data.position.lng;
          self.addForm.latitude = data.position.lat;
          self.center = [data.position.lng, data.position.lat];
          self.address = data.formattedAddress;
          self.markers.push([data.position.lng, data.position.lat]);
        }
        function onError(data) {
          // 失败 启用 ip定位
          AMap.plugin('AMap.CitySearch', function () {
            var citySearch = new AMap.CitySearch();
            citySearch.getLocalCity(function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                // 查询成功，result即为当前所在城市信息
                self.center = [result.bounds.Gb.lng, result.bounds.Gb.lat];
                geocoder.getAddress(
                  [result.bounds.Gb.lng, result.bounds.Gb.lat],
                  function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                      if (result && result.regeocode) {
                        self.address = result.regeocode.formattedAddress;
                        self.$nextTick();
                      }
                    }
                  }
                );
              }
            });
          });
        }
      },
    };
  },
  mounted() {
    Utilcity.$on('cityid', (message) => {
      this.cityCode = message;
      this.getscreen();
    });
    if (localStorage.getItem('cityid')) {
      this.cityCode = localStorage.getItem('cityid');
    }
    this.getscreen();
    this.gethrcompany();
    const self = this;
    setTimeout(function () {
      self.location();
    }, 800);
  },
  methods: {
    //HR公司列表
    gethrcompany() {
      hrcompany().then((res) => {
        this.company = res.data.result;
      });
    },
    //创建资料并发布
    release() {
      this.$refs.formjob.validate((valid) => {
        if (!valid) {
          return this.$message.error('表单错误请重新添加表单');
        } else {
          const self = this;
          this.formjob.attachmentFiles = [];
          this.formjob.albums = [];
          this.business.forEach(function (item, i) {
            if (item.response) {
              self.formjob.attachmentFiles.push({
                key: item.response.result.name,
                value: item.response.result.fileToken,
              });
            }
          });
          this.cominess.forEach(function (item, i) {
            if (item.response) {
              self.formjob.albums.push({
                key: item.response.result.name,
                value: item.response.result.fileToken,
              });
            }
          });
          jobinfo(this.formjob).then((res) => {
            if (res.data.code == 1) {
              this.$message({
                type: 'success',
                message: '企业信息填写成功',
              });
              this.form.reeCid = res.data.result.id;
              this.$('.serialnumber:nth-child(2)').addClass('msg-active');
              this.firstmessage = false;
              this.secondmessage = true;
            } else {
              this.$message({
                type: 'danger',
                message: res.data.message,
              });
            }
          });
        }
      });
    },
    //下一步
    nextstep() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return this.$message.error('表单错误请重新添加表单');
        } else {
          this.form.num = parseInt(this.form.num);
          jobpost(this.form).then((res) => {
            if (res.data.code == 1) {
              this.$message({
                type: 'success',
                message: '职位信息填写成功',
              });
              this.form.reeCid = res.data.result;
              this.$('.serialnumber:nth-child(3)').addClass('msg-active');
              this.firstmessage = false;
              this.secondmessage = false;
              this.thirdmessage = true;
            } else {
              this.$message({
                type: 'danger',
                message: res.data.message,
              });
            }
          });
        }
      });
    },
    // 上传工商信息成功时的钩子
    busSuccess(response, file, fileList) {
      if (response.code === 1) {
        this.business = fileList;
      } else {
        this.business = [];
        this.fileListGS = [];
      }
    },
    // 上传工商信息失败的钩子
    busError(err, file, fileList) {
      fileList = [];
      this.business = [];
      this.fileListGS = [];
      return this.$message.error('上传工商信息失败');
    },
    // 工商信息移除文件时的钩子
    busremove(file, fileList) {
      this.business = fileList;
      if (file.response) {
        //删除图片
        businessId(file.response.result.appendixId);
      } else {
        businessId(file.name);
      }
    },
    // 上传公司相册成功时的钩子
    comSuccess(response, file, fileList) {
      if (response.code === 1) {
        this.cominess = fileList;
      } else {
        this.cominess = [];
        this.fileListCom = [];
      }
    },
    // 上传公司相册失败的钩子
    comError(err, file, fileList) {
      fileList = [];
      this.cominess = [];
      this.fileListCom = [];
      return this.$message.error('上传工商信息失败');
    },
    // 公司相册移除文件时的钩子
    comremove(file, fileList) {
      this.cominess = fileList;
      if (file.response) {
        //删除图片
        businessId(file.response.result.appendixId);
      } else {
        businessId(file.name);
      }
    },
    //公司名称
    companycid() {
      const self = this;
      let cids = this.formjob.cid;
      this.empty();
      this.formjob.cid = cids;
      let companydata = this.company.filter(function (item) {
        return self.formjob.cid == item.companyid;
      });
      this.formjob.fullname = companydata[0].companyname;
      hrcompanyinfo(this.formjob.cid).then((res) => {
        if (res.data.code === 1 && res.data.result.id !== null) {
          self.formjob.fullname = res.data.result.fullName;
          self.formjob.trade = res.data.result.trade;
          self.formjob.gisCoord = res.data.result.gisCoord;
          self.center = [
            res.data.result.gisCoord.longitude,
            res.data.result.gisCoord.latitude,
          ];
          self.markers = [
            res.data.result.gisCoord.longitude,
            res.data.result.gisCoord.latitude,
          ];
          self.address = res.data.result.companyAddress;
          self.formjob.companySize = res.data.result.companySize;
          self.formjob.employeeSize = res.data.result.employeeSize;
          self.formjob.companyProfile = res.data.result.companyProfile;
          self.formjob.companyAddress = res.data.result.companyAddress;
          let cmpInfo = res.data.result.attachmentFiles;
          cmpInfo.forEach(function (item, i) {
            self.fileListGS.push({ name: item.fileName, url: item.filePath });
          });
          let cmpAlbum = res.data.result.albums;
          cmpAlbum.forEach(function (item, i) {
            self.fileListCom.push({ name: item.fileName, url: item.filePath });
          });
        } else {
          this.location();
        }
      });
    },
    empty() {
      this.formjob = {
        albums: [],
        attachmentFiles: [],
        cid: null,
        fullname: '',
        trade: null,
        tradeCode: null,
        companySize: '',
        employeeSize: '',
        companyProfile: '',
        gisCoord: {
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          latitude: 0,
          longitude: 0,
          province: '',
          provinceCode: '',
          srid: 'GCJ-02',
        },
      };
      this.fileListGS = [];
      this.fileListCom = [];
    },
    //所属行业
    tradeCodes() {
      const self = this;
      let data = this.indutrys.filter(function (item) {
        return self.formjob.trade == item.name;
      });
      this.formjob.trade = data[0].name;
    },
    //职位福利
    welfare() {
      const self = this;
      this.welfareCodelist = [];
      this.wels.filter(function (item) {
        for (let i = 0; i < self.welfarelist.length; i++) {
          if (item.name == self.welfarelist[i]) {
            self.welfareCodelist.push(item.code);
          }
        }
      });
      this.form.welfare = this.welfarelist.toString();
      this.form.welfareCode = this.welfareCodelist.toString();
    },
    //薪资范围
    salarys() {
      const self = this;
      let data = this.sals.filter(function (item) {
        return self.form.salaryCode == item.code;
      });
      this.form.salary = data[0].name;
    },
    //可接受应届生
    acceptFgras() {
      if (this.acceptFgra == false) {
        this.form.acceptFgra = 0;
      } else if (this.acceptFgra == true) {
        this.form.acceptFgra = 1;
      }
    },
    //是否面议
    isFaces() {
      if (this.isFace == false) {
        this.form.isFace = 0;
      } else if (this.isFace == true) {
        this.form.isFace = 1;
      }
    },
    //可接受残疾人
    acceptDeforms() {
      if (this.acceptDeform == false) {
        this.form.acceptDeform = 0;
      } else if (this.acceptDeform == true) {
        this.form.acceptDeform = 1;
      }
    },
    //可公开
    phoneOpens() {
      if (this.phoneOpen == false) {
        this.form.phoneOpen = 0;
      } else if (this.phoneOpen == true) {
        this.form.phoneOpen = 1;
      }
    },
    //学历要求
    edu() {
      // console.log(this.form.rcruitVo.educationCode)
      const self = this;
      let data = this.edus.filter(function (item) {
        return self.form.educationCode == item.code;
      });
      this.form.education = data[0].name;
      // console.log(this.form.rcruitVo.education)
    },
    //工作年限
    workExp() {
      // console.log(this.form.rcruitVo.workExpCode)
      const self = this;
      let data = this.workExps.filter(function (item) {
        return self.form.workExpCode == item.code;
      });
      this.form.workExp = data[0].name;
      // console.log(this.form.rcruitVo.workExp)
    },
    getscreen() {
      screen(this.cityCode).then((res) => {
        this.workExps = res.data.result.workExps;
        this.job = res.data.result.job;
        this.sals = res.data.result.sals;
        this.wels = res.data.result.wels.slice(1);
        this.edus = res.data.result.edus;
        this.indutrys = res.data.result.indutrys;
      });
    },

    handleClose() {
      this.dialogVisible = false;
    },
    locatefal() {
      this.dialogVisible = false;
    },

    addurl() {
      this.dialogVisible = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.amap-demo {
  height: 500px;
}
>>> .upload-demo .el-upload-list--picture .el-upload-list__item {
  height: 40px;
  width: 360px;
  margin-top: 5px;
  padding: 5px 10px 5px 90px;
}
>>> .el-upload-list--picture .el-upload-list__item .el-upload-list__item-name {
  margin-top: -20px;
  display: none;
}
>>> .upload-demo .el-upload-list--picture .el-upload-list__item img {
  width: 30px;
  height: 30px;
}
>>> .el-dialog__wrapper .el-dialog .el-dialog__header {
  padding: 0px 20px 20px 0;
  margin-bottom: 10px;
}
>>> .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 10px 20px 30px 20px;
}
.address {
  margin-top: 10px;
  height: 20px;
}
.center div {
  box-sizing: border-box;
}
.center {
  clear: both;
  width: 1140px;
  margin: 10px auto;
  margin-bottom: 50px;
}
.warning {
  display: inline-block;
  width: 1140px;
  background-color: #fffbe4;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 25px;
}
.warning > div {
  float: left;
}
.warning img {
  width: 14px;
  height: 14px;
}
.warning .warningtext div {
  float: left;
  clear: both;
}
.warning span {
  color: #ec4514;
  margin: 0 8px;
}
.warning a {
  color: #719cf0;
  text-decoration: none;
  cursor: pointer;
}
.serialnumber > div {
  float: left;
}
.center .msg-active .circle {
  border: 1px solid #ec4514 !important;
}
.center .msg-active .circle div {
  background-color: #ec4514 !important;
}
.center .msg-active .bottom {
  border-bottom: 1px dotted #ec4514 !important;
}
.center .msg-active .smallcircle {
  background-color: #ec4514 !important;
}
.center .msg-active .circlefont {
  color: #719cf0 !important;
}
.center .serialnumber .circle {
  margin-left: 30px;
  border-radius: 50%;
  border: 1px solid #c7cdd3;
  padding: 1px;
  height: 30px;
  width: 30px;
  color: #fff;
}
.center .serialnumber .circle div {
  border-radius: 50%;
  background-color: #c7cdd3;
  line-height: 30px;
  text-align: center;
  height: 26px;
  width: 26px;
  color: #fff;
}
.center .serialnumber .circlefont {
  color: #c7cdd3;
  font-size: 18px;
  margin: 0 5px 0 10px;
}
.center .serialnumber .bottom {
  border-bottom: 1px dotted #c7cdd3;
  width: 150px;
  padding-top: 15px;
}
.center .serialnumber .smallcircle {
  border-radius: 50%;
  background-color: #c7cdd3;
  margin: 11px 0 0 3px;
  height: 8px;
  width: 8px;
}
.center .positionmsg {
  display: block;
  margin: 80px 0 30px -50px;
  font-size: 18px;
}
.center .el-form {
  margin-left: 100px;
}
.lf {
  margin: 0 10px;
}
>>> .center .el-form .el-form-item__content {
  line-height: 20px !important;
}
>>> .center .el-form .el-form-item__content label .el-checkbox-button__inner {
  margin: 10px 10px 0 0;
  border-left: 1px solid #dcdfe6;
}
.center .addfuli {
  margin-top: 10px;
}
>>> .center .quill-editor {
  width: 800px;
  height: 300px;
}
>>> .center .quill-editor .ql-container {
  height: 200px;
}
.center .phone .el-checkbox {
  margin: 0 10px;
}
.center .phone .el-button {
  display: block;
  margin: 10px 0;
}
.center .email {
  color: #999999;
  margin: 20px 0;
}
.center .el-button--danger {
  background-color: #fff;
  border-color: #fff;
  color: #f56c6c;
}
.center .checkboxm {
  margin-left: 10px;
}
>>> .el-form .el-form-item .el-form-item__content {
  margin-left: 110px !important;
}
.nextstep,
.release {
  margin-left: 209px;
  padding: 10px 100px;
  border-radius: 0;
}
>>> .distpicker-address-wrapper label select {
  width: 150px;
  margin-right: 10px;
}
.thirdmsg {
}
.third {
  text-align: center;
  margin: 200px;
  font-size: 40px;
}
.center{
    width: 1140px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
}
</style>
